<template>
  <div class="capital-account-detail">
    <a-spin :spinning="loading">
      <div class="content-overflow mt10">
        <section class="left-div fixContent" :style="{ height: scrollHeight }">
          <a-form-model ref="ruleForm" :model="formData">
            <ModalBox id="BaseInfo" :title="$t('基本信息')">
              <a-row :gutter="16">
                <a-col :span="6">
                  <a-form-model-item :label="$t(`结算组织`)" required>
                    <CommonSelect
                      :isBu="true"
                      :list="$store.state.settlementBuList"
                      :disabled="isDisabled"
                      :code.sync="formData.businessUnitCode"
                      :name.sync="formData.businessUnitName"
                      @change="changeBU"
                    />
                  </a-form-model-item>
                </a-col>
                <a-col :span="6">
                  <a-form-model-item :label="$t(`金融机构类别`)" required>
                    <CommonSelect
                      :placeholder="$t(`金融机构类别`)"
                      :list="capital_type"
                      :code.sync="formData.type"
                      :disabled="isDisabled"
                    />
                  </a-form-model-item>
                </a-col>
                <a-col :span="6">
                  <a-form-model-item :label="$t(`账户名称`)" required>
                    <a-input
                      v-model="formData.name"
                      :placeholder="$t(`账户名称`)"
                      :disabled="isDisabled"
                    ></a-input>
                  </a-form-model-item>
                </a-col>
                <a-col :span="6">
                  <a-form-model-item :label="$t(`默认货币`)" required>
                    <SelectCurrency :code.sync="formData.currency" :disabled="isDisabled" />
                  </a-form-model-item>
                </a-col>
                <a-col :span="6">
                  <a-form-model-item :label="$t(`银行名称`)">
                    <a-input
                      v-model="formData.bankName"
                      :placeholder="$t(`银行名称`)"
                      :disabled="isDisabled"
                    />
                  </a-form-model-item>
                </a-col>
                <a-col :span="6">
                  <a-form-model-item :label="$t(`银行账号`)" required>
                    <a-input
                      v-model="formData.code"
                      :placeholder="$t(`银行账号`)"
                      :disabled="isDisabled"
                    />
                  </a-form-model-item>
                </a-col>
                <a-col :span="6">
                  <a-form-model-item :label="$t(`账户用途`)" required>
                    <CommonSelect
                      :placeholder="$t(`账户用途`)"
                      :list="account_purpose"
                      :code.sync="formData.accountPurpose"
                      :disabled="isDisabled"
                    />
                  </a-form-model-item>
                </a-col>
                <a-col :span="6">
                  <a-form-model-item :label="$t(`存款类型`)" required>
                    <CommonSelect
                      :placeholder="$t(`存款类型`)"
                      :list="bank_capital_type"
                      :code.sync="formData.subType"
                      :disabled="isDisabled"
                    />
                  </a-form-model-item>
                </a-col>
                <a-col :span="6">
                  <a-form-model-item :label="$t(`备注`)">
                    <a-input
                      v-model="formData.remark"
                      :placeholder="$t(`备注`)"
                      :disabled="isDisabled"
                    />
                  </a-form-model-item>
                </a-col>
              </a-row>
              <a-row :gutter="16" class="mt10">
                <a-col :span="6">
                  <a-form-model-item :label="$t(`默认收款户`)">
                    <a-switch
                      v-model="formData.isDefaultReceiveAccount"
                      :disabled="isDisabled"
                    ></a-switch>
                  </a-form-model-item>
                </a-col>
                <a-col :span="6">
                  <a-form-model-item :label="$t(`默认付款户`)">
                    <a-switch
                      v-model="formData.isDefaultPayAccount"
                      :disabled="isDisabled"
                    ></a-switch>
                  </a-form-model-item>
                </a-col>
              </a-row>
            </ModalBox>
          </a-form-model>
        </section>
        <OperationLog
          ref="OperationLogRef"
          :scrollHeight="scrollHeight"
          :actionSn="sn"
          :type="docType"
          v-if="isDetail"
        />
      </div>
      <BottomBtns
        :isEdit.sync="isEdit"
        :isDetail="isDetail"
        :draftLoading="draftLoading"
        :saveLoading="saveLoading"
        :status="formData.status"
        @submit="onSubmit"
        @cancel="getDetailInfo"
        @back="backForm"
      />
    </a-spin>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import http from '@/service/axios'
import api from '@/service/api'
import SelectCurrency from '@component/selectCurrency'
import BottomBtns from '@component/bottomBtns'
import OperationLog from '@component/operationLog'
import deepClone from '@/common/deepClone'
import debounce from '@/common/debounce'
import { convertKeysToCamelCase, goBack } from '@/common'
export default {
  name: 'CapitalAccountDetail',
  components: { SelectCurrency, BottomBtns, OperationLog },
  data() {
    return {
      docType: 'CapitalAccount',
      id: undefined,
      sn: undefined,
      isDetail: false, //是否是详情页
      isEdit: false, //是否编辑状态
      formData: {
        name: undefined,
        code: undefined,
        currency: undefined,
        businessUnitCode: undefined,
        businessUnitName: undefined,
        type: undefined,
        bankName: undefined,
        accountPurpose: undefined,
        subType: undefined,
        remark: undefined,
        isDefaultReceiveAccount: false,
        isDefaultPayAccount: false,
      },
      loading: false,
      draftLoading: false,
      saveLoading: false,
    }
  },
  activated() {
    this.init()
  },
  beforeRouteUpdate(to, from, next) {
    Object.assign(this.$data, this.$options.data())
    this.$nextTick(() => {
      this.init()
    })
    next()
  },
  computed: {
    ...mapState(['bank_capital_type', 'capital_type', 'account_purpose']),
    isDisabled() {
      return this.isDetail && !this.isEdit
    },
    scrollHeight() {
      return window.innerHeight - 200 + 'px'
    },
  },
  methods: {
    init() {
      this.isDetail = false
      this.isEdit = false
      if (!this.$route.query.id) {
        Object.assign(this.$data, this.$options.data())
      } else {
        this.isDetail = true
        this.id = this.$route.query.id
        this.sn = this.$route.query.sn
        this.$nextTick(() => {
          this.$refs.OperationLogRef.queryList()
        })
      }
      this.getDetailInfo()
    },
    changeBU(value) {
      this.formData.currency = value.currency
    },
    changeTabs(value) {
      document.querySelector(`#${value}`).scrollIntoView(true)
    },
    chooseAccountingDate(data) {
      this.formData.accountingDate = data.data
    },
    async getDetailInfo() {
      if (this.id) {
        this.loading = true
        await http({
          url: api.getCapitalAccountDetail,
          data: {
            id: this.id,
          },
          success: (res) => {
            let result = convertKeysToCamelCase(res.result)
            result.isDefaultReceiveAccount = Boolean(result.isDefaultReceiveAccount)
            result.isDefaultPayAccount = Boolean(result.isDefaultPayAccount)
            result.status = 'WAIT_CHECK'
            this.formData = deepClone(result)
          },
        })
        this.loading = false
      }
    },
    backForm(back = 0) {
      goBack('capitalAccountList', back)
    },
    onSubmit: debounce(async function (status) {
      const data = deepClone(this.formData)
      data.orderStatus = status
      if (this.id) {
        data.id = this.id
      }
      if (status === 'DRAFT') {
        this.draftLoading = true
      } else {
        this.saveLoading = true
      }
      data.isDefaultPayAccount = +data.isDefaultPayAccount
      data.isDefaultReceiveAccount = +data.isDefaultReceiveAccount
      await http({
        url: api.createOrUpdateCapitalAccount,
        data,
        type: 'post',
        hasLoading: true,
        success: (res) => {
          if (res.success) {
            this.$success({
              title: this.$t(`成功`),
              content: this.$t(`保存成功`),
            })
            if (this.isDetail) {
              this.init()
            } else {
              this.backForm()
            }
          } else {
            this.$error({
              title: this.$t(`错误`),
              content: this.$t(`创建失败`),
            })
          }
        },
      })
      this.draftLoading = false
      this.saveLoading = false
    }),
  },
}
</script>
